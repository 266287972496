/* SCRIPTS FITXA TXIKIAK */

function erroreakIdatzi(xhr, textStatus, thrownError) {
    $('#erroreak').css('display', 'block');
    $(".erroreakZerrenda").text('');
    $.each($.parseJSON(xhr.responseText),
        function (ind, elem) {
            $(".erroreakZerrenda").append('<li>' + elem + '</li>');
        });
}

function gehituBalioaSelecten(e, form, ruta, eremua_select, eremua_berria, div_berria, eremu_nagusia, eremu_nagusia_izena, eremua_select2) {
    if (eremu_nagusia == 'musikaTaldea_id') eremu_nagusia == 'entitatea_id';

    var eremu_nagusia = eremu_nagusia || '';
    var eremu_nagusia_izena = eremu_nagusia_izena || '';
    var eremua_select2 = eremua_select2 || '';

    e.preventDefault();
    var form = form.serialize();

    if (eremu_nagusia != '') {
        var balioa = eremu_nagusia.val();
        form = form + '&' + eremu_nagusia_izena + '=' + balioa;
    }
    $.ajax({
        data: form,
        url: '/' + ruta + '/berriaTxikia/',
        success: function (data) {
            var emaitza = data.html;
            var balioa = eremua_berria.val();

            eremua_select.append('<option value="' + emaitza + '" selected>' + balioa + '</option>');
            if (eremu_nagusia != '') {
                eremua_select2.val('');
            }

            if (eremua_select2 != '') {
                eremua_select2.text(balioa);
            }

            //Itxi modala
            //div_berria.css('display', 'none');
            div_berria.modal('toggle');

            //ikusi
            var botoia = eremua_select.attr("id") + '_ikusi';
            botoia = $("#" + botoia);
            if (typeof (botoia) != 'undefined')
                botoia.attr("eremua", eremua_select.val());

            gordeFitxaForzatu();
        },
        error: function (xhr, textStatus, thrownError) {
            erroreakIdatzi(xhr, textStatus, thrownError)
        }
    });
}