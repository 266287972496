function formatuakZbkia(nStr) {
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? ',' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + '.' + '$2');
    }
    return x1 + x2;
}

function taulaAktibatu(elem, hizkuntza, izena, bezeroa, zutabeaTotalak, zutabeaEzkutatu) {
    if (hizkuntza == 'eu') var fitxHizkuntza = '/fonts/Basque.json';
    if (hizkuntza == 'es') fitxHizkuntza = '/fonts/Spanish.json';

    //$.fn.dataTable.Buttons.swfPath = '/assets/DataTables/extensions/Buttons/swf/flashExport.swf';

    elem.DataTable({
        scrollY: "500px",
        // scrollX:        true,
        scrollCollapse: true,
        // fixedHeader: true,
        //paging:         false,
        // fixedColumns:   {
        //     leftColumns: 2//Le indico que deje fijas solo las 2 primeras columnas
        // },
        //scrollX: true,

        // "processing": true,
        // "serverSide": true,
        // "ajax": "entitateak/taula",
        // "iDisplayLength":3,

        // serverSide: true,
        // deferRender:    true,
        //  ajax: 'resources/views/appHarremana/Norbanakoak/NorbanakoaEntitatea/data.json',
        // pagingType: "scrolling",
        // deferRender: true,

        deferRender: true, /* taula handiak */
        footerCallback: function (row, data, start, end, display) {
            var api = this.api(), data;

            var balioaAtera = function (i) {
                return typeof i === 'string' ? i.replace(/[\€.]/g, '').replace(',', '.') * 1 : typeof i === 'number' ? i : 0;
            };

            if (zutabeaTotalak != "") {
                zutabeaTotalak = zutabeaTotalak + ""; //split erabili ahal izateko
                var zutabeak = zutabeaTotalak.split(",");
                for (var k = 0; k < zutabeak.length; k++) {
                    // Total over all pages
                    zutabeZbkia = zutabeak[k];

                    total = api
                        .column(zutabeZbkia)
                        .data()
                        .reduce(function (a, b) {
                            return balioaAtera(a) + balioaAtera(b);
                        }, 0);

                    // Total over this page
                    totalaIkusi = api
                        .column(zutabeZbkia, {page: 'current'})
                        .data()
                        .reduce(function (a, b) {
                            return balioaAtera(a) + balioaAtera(b);
                        }, 0);

                    // Update footer
                    totalaIkusi = Number(totalaIkusi).toFixed(2);
                    // totalaIkusi = totalaIkusi.replace('.', ',');

                    dezimalak = totalaIkusi.split(".");
                    if (dezimalak[1] == 0) totalaIkusi = dezimalak[0];

                    totala = Number(total).toFixed(2);
                    // totala = totala.replace('.', ',');

                    dezimalak = totala.split(".");
                    if (dezimalak[1] == 0) totala = dezimalak[0];

                    $(api.column(zutabeZbkia).footer()).html(
                        '<span class="kolorea">' + formatuakZbkia(totalaIkusi) + ' (' + formatuakZbkia(totala) + ')</span>'
                    );
                }
            }
        },
        oLanguage: {
            sUrl: fitxHizkuntza
        },

        dom: '<<"top"Blfr><t><"bottom"ip>>',

        // columnDefs: [{
        //     targets: [],
        //     orderData: []
        // }],

        aaSorting: [],

        //"pagingType": "full_numbers",

        select: true,
        stateSave: true,
        //responsive: true,

        pageLength: 20,
        aLengthMenu: [10, 20, 25, 50, 100],

        // columnDefs: [
        //     // {targets: '_all', visible: false},
        //     {
        //         targets: [12],
        //         visible: false
        //     }
        // ],

        buttons: [
            // 'columnsToggle',

            // {
            //     extend: 'collection',
            //     text:  '<i class="fa fa-columns" aria-hidden="true"></i> Nire Botoia',
            //     action: function ( e, dt, node, config ) {
            //         alert(dt.rows('.selected').data().length);
            //         //dt.ajax.reload();
            //         // alert(this.row( { selected: true } ).index())
            //     }
            // },
            {
                extend: 'colvis',
                autoClose: true,
                postfixButtons: ['colvisRestore'],
                text: '<i class="fa fa-columns" aria-hidden="true"></i> Zutabeak',
                collectionLayout: 'two-column'
            },
            {
                extend: 'collection',
                text: ' <i class="fa fa-table" aria-hidden="true"></i> Taula ',
                autoClose: true,
                //swfPath: "/assets/DataTables/extensions/Buttons/swf/flashExport.swf",
                buttons: [
                    // {
                    //     extend: "print",
                    //     text: 'Inprimatu',
                    //     //autoPrint: false,
                    //     exportOptions: {
                    //         columns: ':visible'
                    // },
                    // buttonClass: "DTTT_button_print",
                    // customize: function ( win ) {
                    //     $(win.document.body)
                    //         .css( 'font-size', '10pt' )
                    //         .prepend(
                    //             '<img src="'+BASE+'/assets/'+bezeroa+'/logoak/logo.png" alt="" style="position:relative; top:0; left:0;" />'
                    //         );
                    //
                    //     $(win.document.body).find( 'table' )
                    //         .addClass( 'compact' )
                    //         .css( 'font-size', 'inherit' );
                    //     }
                    // },
                    {
                        extend: "csvHtml5",
                        text: '<i class="fa fa-file-text-o fa-2x"></i> CSV',
                        titleAttr: 'CSV',
                        buttonClass: "DTTT_button_csv",
                        exportOptions: {
                            columns: ':visible',
                        },
                        title: izena,
                        charSet: "utf8"
                    },
                    // {
                    //     extend: 'pdf',
                    //     download: 'open',
                    //     text: '<i class="fa fa-file-pdf-o fa-2x"></i> PDF (H)',
                    //     titleAttr: 'PDF  (H)',
                    //     exportOptions: {
                    //         columns: ':visible'
                    //     },
                    //     orientation: "landscape",
                    //     //PdfMessage: izena,
                    //     //ButtonText: " Pdf (H)",
                    //     title: izena,
                    //     charSet: "utf8"
                    // },
                    // {
                    //     extend: 'pdf',
                    //     download: 'open',
                    //     text: '<i class="fa fa-file-pdf-o fa-2x"></i> PDF (B)',
                    //     titleAttr: 'PDF  (B)',
                    //     buttonClass: "DTTT_button_pdf",
                    //     exportOptions: {
                    //         columns: ':visible'
                    //     },
                    //     orientation: "portrait",
                    //     PdfMessage: izena,
                    //     //ButtonText: " Pdf (B)",
                    //     title: izena,
                    //     CharSet: "utf8"
                    // },
                    {
                        extend: 'copyHtml5',
                        text: '<i class="fa fa-files-o  fa-2x"></i> Kopiatu',
                        titleAttr: 'Kopiatu'
                    },
                ]
            },

            {
                extend: 'collection',
                text: ' <i class="fa fa-list" aria-hidden="true"></i> Lerroak ',
                autoClose: true,
                // SwfPath: "/assets/DataTables/extensions/Buttons/swf/flashExport.swf",
                buttons: [
                    {
                        // {
                        //     extend: 'print',
                        //     text: 'Inprimatu Lerroak',
                        //     exportOptions: {
                        //         modifier: {
                        //             selected: true,
                        //             columns: ':visible'
                        //         },
                        //     },
                        //     buttonClass: "DTTT_button_print",
                        //     customize: function ( win ) {
                        //         $(win.document.body)
                        //             .css( 'font-size', '10pt' )
                        //             .prepend(
                        //                 '<img src="'+BASE+'/assets/'+bezeroa+'/logoak/logo.png" alt="" style="position:relative; top:0; left:0;" />'
                        //             );
                        //
                        //         $(win.document.body).find( 'table' )
                        //             .addClass( 'compact' )
                        //             .css( 'font-size', 'inherit' );
                        //     }
                        // },

                        extend: "csvHtml5",
                        text: '<i class="fa fa-file-text-o fa-2x"></i> CSV',
                        titleAttr: 'CSV Lerroak',
                        exportOptions: {
                            columns: ':visible',
                            modifier: {
                                selected: true,
                                columns: ':visible'
                            },
                        },
                        title: izena,
                        charSet: "utf8"
                    },
                    // {
                    //     extend: "pdf",
                    //     text: '<i class="fa fa-file-pdf-o fa-2x"></i> PDF (H)',
                    //     titleAttr: 'PDF  (H)',
                    //     exportOptions: {
                    //         columns: ':visible',
                    //         modifier: {
                    //             selected: true,
                    //             columns: ':visible'
                    //         },
                    //     },
                    //     orientation: "landscape",
                    //     PdfMessage: izena,
                    //     title: izena,
                    //     CharSet: "utf8"
                    // },
                    // {
                    //     extend: "pdf",
                    //     text: '<i class="fa fa-file-pdf-o fa-2x"></i> PDF (B)',
                    //     titleAttr: 'PDF  (B)',
                    //     exportOptions: {
                    //         columns: ':visible',
                    //         modifier: {
                    //             selected: true,
                    //             columns: ':visible'
                    //         },
                    //     },
                    //     orientation: "portrait",
                    //     PdfMessage: izena,
                    //     title: izena,
                    //     CharSet: "utf8"
                    // },
                    {
                        extend: 'copyHtml5',
                        text: '<i class="fa fa-files-o  fa-2x"></i> Kopiatu',
                        titleAttr: 'Kopiatu',
                        exportOptions: {
                            columns: ':visible',
                            modifier: {
                                selected: true,
                                columns: ':visible'
                            },
                        },
                    },
                ]
            },
        ],
    });

    // elem.columns( [ 0, 2 ] ).visible( false, false );
    // elem.columns.adjust().draw( false );

    // elem.column( 1 ).visible( false, false );
    // alert(elem.column( 1 ).visible());
    //
    // elem.columns.adjust().draw( false );

    // elem.buttons().container()
    //     .appendTo( '#wrapper .col-sm-6:eq(0)' );
}


// function azpitaulaAktibatu(elem, hizkuntza, izena) {
//
//     if (hizkuntza == 'eu') fitxHizkuntza = 'assets/fonts/Basque.json';
//     if (hizkuntza == 'es') fitxHizkuntza = 'assets/fonts/Spanish.json';
//
//     elem.DataTable({
//         oLanguage: {
//             sUrl: fitxHizkuntza
//         },
//         dom: 'TRC<"clear">lfrtip',
//         //"sDom": 'TRC<"clear">lfrtip',
//         //"sDom": 'T<"clear"><"H"lTf>rt<"F"ip>',
//         columnDefs: [
//             {
//                 visible: false,
//                 targets: 1
//             }
//         ],
//         aaSorting: [],
//         "oTableTools": {
//             "sSwfPath": "assets/DataTables/TableTools/swf/copy_csv_xls_pdf.swf",
//             "aButtons": [
//                 {
//                     "sExtends": "collection",
//                     "sButtonText": " Save ",
//                     "sbuttonClass": "glyphicon glyphicon-floppy-saved hidden-xs",
//                     "aButtons": [
//                         {
//                             "sExtends": "print",
//                             "sbuttonClass": "DTTT_button_print"
//                         },
//                         {
//                             "sExtends": "csv",
//                             "sbuttonClass": "DTTT_button_csv",
//                             "mColumns": "visible",
//                             "sButtonText": " Csv",
//                             "stitle": izena + ".csv",
//                             "sCharSet": "utf8"
//                         },
//                         {
//                             "sExtends": "pdf",
//                             "sbuttonClass": "DTTT_button_pdf",
//                             "mColumns": "visible",
//                             "sPdfOrientation": "landscape",
//                             "sPdfMessage": izena,
//                             "sButtonText": " Pdf (H)",
//                             "stitle": izena + ".pdf",
//                             "sCharSet": "utf8"
//                         },
//                         {
//                             "sExtends": "pdf",
//                             "sbuttonClass": "DTTT_button_pdf",
//                             "sPdfOrientation": "portrait",
//                             "sPdfMessage": izena,
//                             "sButtonText": " Pdf (B)",
//                             "stitle": izena + ".pdf",
//                             "sCharSet": "utf8"
//                         }
//                     ]
//                 }
//             ]
//         },
//
//         aLengthMenu: [10, 15, 20, 25],
//         stateSave: true,
//         responsive: true
//     });
// }


function azpiTaulaAktibatu(elem, hizkuntza, izena, bezeroa, zutabeaTotalak) {

    if (hizkuntza == 'eu') fitxHizkuntza = '/fonts/Basque.json';
    if (hizkuntza == 'es') fitxHizkuntza = '/fonts/Spanish.json';

    //$.fn.dataTable.Buttons.swfPath = '/assets/DataTables/extensions/Buttons/swf/flashExport.swf';

    elem.DataTable({
        oLanguage: {
            sUrl: fitxHizkuntza
        },

        // dom: '<<"top"Blfr><t><"bottom"ip>>',
        dom: '<<"top-left"f><t><"bottom"ip>>',

        // columnDefs: [{
        //     targets: [],
        //     orderData: []
        // }],

        aaSorting: [],
        pageLength: 20,

        footerCallback: function (row, data, start, end, display) {
            var api = this.api(), data;

            var balioaAtera = function (i) {
                return typeof i === 'string' ? i.replace(/[\€.]/g, '').replace(',', '.') * 1 : typeof i === 'number' ? i : 0;
            };

            // if (zutabeaTotalak != "") {
            //     zutabeaTotalak = zutabeaTotalak + ""; //split erabili ahal izateko
            //     var zutabeak = zutabeaTotalak.split(",");
            //     for (var k = 0; k < zutabeak.length; k++) {
            //         // Total over all pages
            //         zutabeZbkia = zutabeak[k];
            //
            //         total = api
            //             .column(zutabeZbkia)
            //             .data()
            //             .reduce(function (a, b) {
            //                 return balioaAtera(a) + balioaAtera(b);
            //             }, 0);
            //
            //         // Total over this page
            //         totalaIkusi = api
            //             .column(zutabeZbkia, {page: 'current'})
            //             .data()
            //             .reduce(function (a, b) {
            //                 return balioaAtera(a) + balioaAtera(b);
            //             }, 0);
            //
            //         // Update footer
            //         totalaIkusi = Number(totalaIkusi).toFixed(2);
            //         // totalaIkusi = totalaIkusi.replace('.', ',');
            //
            //         dezimalak = totalaIkusi.split(".");
            //         if (dezimalak[1] == 0) totalaIkusi = dezimalak[0];
            //
            //         totala = Number(total).toFixed(2);
            //         // totala = totala.replace('.', ',');
            //
            //         dezimalak = totala.split(".");
            //         if (dezimalak[1] == 0) totala = dezimalak[0];
            //
            //         $(api.column(zutabeZbkia).footer()).html(
            //             '<span class="kolorea">' + formatuakZbkia(totalaIkusi) + ' (' + formatuakZbkia(totala) + ')</span>'
            //         );
            //     }
            // }
        },

        //"pagingType": "full_numbers",

        // select: true,
        // stateSave: true,
        // responsive: true,

        // aLengthMenu: [20, 25, 50, 100],

        // buttons: [
        //     {
        //         extend: 'colvis',
        //         autoClose: true,
        //         postfixButtons: ['colvisRestore'],
        //         text: '<i class="fa fa-columns" aria-hidden="true"></i> Zutabeak',
        //         //collectionLayout: 'two-column'
        //     },
        //     {
        //         extend: 'collection',
        //         text: ' <i class="fa fa-table" aria-hidden="true"></i> Taula ',
        //         autoClose: true,
        //         //swfPath: "/assets/DataTables/extensions/Buttons/swf/flashExport.swf",
        //         buttons: [
        //             // {
        //             //     extend: "print",
        //             //     text: 'Inprimatu',
        //             //     //autoPrint: false,
        //             //     exportOptions: {
        //             //         columns: ':visible'
        //             // },
        //             // buttonClass: "DTTT_button_print",
        //             // customize: function ( win ) {
        //             //     $(win.document.body)
        //             //         .css( 'font-size', '10pt' )
        //             //         .prepend(
        //             //             '<img src="'+BASE+'/assets/'+bezeroa+'/logoak/logo.png" alt="" style="position:relative; top:0; left:0;" />'
        //             //         );
        //             //
        //             //     $(win.document.body).find( 'table' )
        //             //         .addClass( 'compact' )
        //             //         .css( 'font-size', 'inherit' );
        //             //     }
        //             // },
        //             {
        //                 extend: "csvHtml5",
        //                 text: '<i class="fa fa-file-text-o fa-2x"></i> CSV',
        //                 titleAttr: 'CSV',
        //                 buttonClass: "DTTT_button_csv",
        //                 exportOptions: {
        //                     columns: ':visible',
        //                 },
        //                 title: izena,
        //                 charSet: "utf8"
        //             },
        //             // {
        //             //     extend: 'pdf',
        //             //     download: 'open',
        //             //     text: '<i class="fa fa-file-pdf-o fa-2x"></i> PDF (H)',
        //             //     titleAttr: 'PDF  (H)',
        //             //     exportOptions: {
        //             //         columns: ':visible'
        //             //     },
        //             //     orientation: "landscape",
        //             //     //PdfMessage: izena,
        //             //     //ButtonText: " Pdf (H)",
        //             //     title: izena,
        //             //     charSet: "utf8"
        //             // },
        //             // {
        //             //     extend: 'pdf',
        //             //     download: 'open',
        //             //     text: '<i class="fa fa-file-pdf-o fa-2x"></i> PDF (B)',
        //             //     titleAttr: 'PDF  (B)',
        //             //     buttonClass: "DTTT_button_pdf",
        //             //     exportOptions: {
        //             //         columns: ':visible'
        //             //     },
        //             //     orientation: "portrait",
        //             //     PdfMessage: izena,
        //             //     //ButtonText: " Pdf (B)",
        //             //     title: izena,
        //             //     CharSet: "utf8"
        //             // },
        //             {
        //                 extend: 'copyHtml5',
        //                 text: '<i class="fa fa-files-o  fa-2x"></i> Kopiatu',
        //                 titleAttr: 'Kopiatu'
        //             },
        //         ]
        //     },
        // ],
    });

    // elem.buttons().container()
    //     .appendTo( '#wrapper .col-sm-6:eq(0)' );
}